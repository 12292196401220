<template>
  <Header
    ref="headerRef"
    type="BALANCE_SHEET"
    :title="t('menus.reports')"
    :showRegenerateButton="false"
    :updatedOn="updatedTime ?? ''"
  />
  <ReportsMenus
    ref="ReportsMenusRef"
    type="BALANCE_SHEET"
    :title="t('project.nav.balanceSheet')"
    :params="params"
    @onChangDateTime="onChangDateTime"
  />

  <div v-loading="loading" class="elv-reports-balance-sheet-content">
    <FinancialStatement
      financial-type="balance-sheet"
      :params="params"
      :isShowRegeneratedInfo="ReportsMenusRef?.isShowRegeneratedInfo"
    />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import Header from '../components/Header.vue'
import ReportsMenus from '../../components/ReportsMenus.vue'
import { useReportStore } from '@/stores/modules/reports/index'
import FinancialStatement from '../components/FinancialStatement.vue'

const { t } = useI18n()
const route = useRoute()
const reportStore = useReportStore()
const { loading } = storeToRefs(reportStore)

const headerRef = useTemplateRef('headerRef')
const ReportsMenusRef = useTemplateRef<InstanceType<typeof ReportsMenus>>('ReportsMenusRef')

const updatedTime = computed(() => {
  return reportStore.financialData.updatedOn
})

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const params = computed(() => {
  return {
    period:
      ReportsMenusRef.value?.periodValue === 'date' ? 'DAY' : ReportsMenusRef.value?.periodValue.toLocaleUpperCase(),
    dateRange: ReportsMenusRef.value?.dateValue
  }
})

const onChangDateTime = async (data: object) => {
  await reportStore.financialsDataInit(entityId.value, 'balance-sheet', data)
}
</script>

<style lang="scss" scoped>
.elv-reports-balance-sheet-content {
  min-height: 65vh;
  height: calc(100% - 163px);
}
</style>
